/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { AuthContext } from "src/context/Auth";

import {
  FaWallet,
  FaSignOutAlt,
  FaUserEdit,
  FaSignInAlt,
} from "react-icons/fa";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";

const sections = [
  // {
  //   title: "Edit Profile",
  //   // href: "/edit-profile",
  //   icon: FaUserEdit,
  // },
  {
    title: "Profile",
    href: "/my-profile",
    icon: FaSignOutAlt,
  },
  {
    title: "Change Password",
    href: "/changePassword",
    icon: FaSignOutAlt,
  },
  {
    title: "Logout",
    href: "/dashboard",
    icon: FaSignOutAlt,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    top: "30px",
    right: "43px",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    padding: "14px",
    overflow: "unset",
    position: "absolute",
    background: "#181C32",
    borderRadius: "10px",
    color: '#fff'
  },
  avatar: {
    cursor: "pointer",
    // position: "absolute",
    // right: "52px",
    // zIndex: "1",
    width: 50,
    height: 53,
    backgroundColor: "transparent !important"
    // "@media (max-width: 767px)": {
    //   width: "30px",
    //   height: "30px",
    // },
  },
  avatarBig: {
    cursor: "pointer",
    width: 70,
    height: 70,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  name: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "end",

    color: "#000000",
  },
  email: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#000000",
    textAlign: "end",
  },
  editButton: {
    color: "#fff",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.02em",
    "& .MuiButton-label" :{
      
      justifyContent: "left !important"
    }
  },
  userButton: {
    width: "143.21px",
    height: "44.7px",
    background: "#F2F5FA",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "space-around",
    paddingLeft: "50px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    // fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",

    color: "#000000",

    // "& .downArrow":{
    //   // paddingLeft:"4px"
    // }
  },
  profileBox: {
    display: "flex",
    alignItems: "center",
    justifyContent:"flex-end",
    "@media (max-width: 399px)" :{
flexDirection: "column-reverse"
    }
  },
 
}));

const NavBar = () => {
  const classes = useStyles();
  const [rightBar, setRightBar] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({ firstName: "", lastName: "" });
  const [isLoading, setIsLoading] = useState(false);

  const confirmationHandler = () => {
    history.push("/");
    window.localStorage.removeItem("admintoken");
    // window.localStorage.clear();
    sessionStorage.clear();
  };

  const viewProfile = async () => {
    setIsLoading(true);


    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewAdminProfile,
        headers: {
          token: window.localStorage.getItem("admintoken"),
        }
      });

      setUserData(res.data.result);


      setIsLoading(false);
      console.log(res?.data?.result?.firstName, "result")
    }

    catch (error) {
      console.log(error);
      toast.error(error?.res?.data?.responseMessage);
      setIsLoading(false);
    }
  };
  
  console.log(userData, "userdata")
  useEffect(() => {

    viewProfile();
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column" >
      {open && (
        <ConfirmationDialog
          open={open}
          
          handleClose={() => setOpen(false)}
          title= {"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
        />
      )}

      {sections.map((section, i) => {
        const Icon = section.icon;
        return (
          <Box pt={1}>
            <Button
              className={classes.editButton}
              fullWidth
              key={i}
              onClick={() => {
                section.title === "Logout"
                  ? setOpen(true)
                  : history.push(section.href);
              }}
            >
              {section.title}{" "}
            </Button>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <>
   <Box className={classes.profileBox}>
   <Typography  style={{color:'black'}}>
  {userData.firstName} {userData.lastName}
   </Typography>
      <Avatar
        className={classes.avatar}
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />

</Box>
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
