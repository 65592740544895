
const url = "https://nodepune-aivideogenerator.mobiloitte.io/api/v1";

export const socketNotification = "";
export const baseUrl = "";


const admin = `${url}/admin`;



const ApiConfig = {

  superAdminLogin: `${admin}/superAdminLogin`,
  forgotPassword: `${admin}/forgotPassword`,
  verifyOTP: `${admin}/verifyOTP`,
  resentOtp: `${admin}/resentOtp`,
  resetPassword: `${admin}/resetPassword`,
  viewUserList: `${admin}/viewUserList`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  viewUserProfile: `${admin}/viewUserProfile`,
  adminCreateUser: `${admin}/adminCreateUser`,
  viewAdminProfile: `${admin}/viewAdminProfile`,
  updateAdminProfile: `${admin}/updateAdminProfile`,
  adminGetAllProjects: `${admin}/adminGetAllProjects`,
  blockUnblockOrganization: `${admin}/blockUnblockOrganization`,
  deleteOrganization: `${url}/organization/deleteOrganization`,
  adminViewSingleProject: `${admin}/adminViewSingleProject`,
  addOrganization: `${url}/organization/addOrganization`,
  superAdmingetList: `${admin}/superAdmingetList`,
  createSubSuperAdmin: `${admin}/createSubSuperAdmin`,
  changePassword: `${admin}/changePassword`,
  adminDashboard: `${admin}/adminDashboard`,
  staticContentList:`${url}/static/staticContentList`,
  viewStaticContent:`${url}/static/viewStaticContent`,
  editStaticContent:`${url}/static/editStaticContent`,
};

export default ApiConfig;

