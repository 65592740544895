import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Dialog,
  Hidden,
  Avatar,
  Grid,
  Typography,
  TextField,
  IconButton,InputAdornment,
} from "@material-ui/core";

import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@material-ui/core/DialogContent";
import SettingsContext from "src/context/SettingsContext";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import ApiConfig from "src/config/APICongig";
import { AuthContext } from "src/context/Auth";
import { UserContext } from "src/context/User";
import { Link, useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    // background:
    //   "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
    height: 266,
    background: "#181C32",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& svg": {
      color: theme.palette.text.primary,
      "@media(max-width:767px)": {
        fontSize: "18px",
      },
    },
    "& .leftBox": {
      width: "100%",
      maxWidth: "219px",
      backgroundColor: "#0F0037",
      padding: "18px 10px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      marginTop: "19px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "200px",
      },
      "@media(max-width:1279px)": {
        marginTop: "0px",
        padding: "10px",
      },
      "@media(max-width:667px)": {
        maxWidth: "140px",
      },
      "& img": {
        width: "100%",
        maxWidth: "130px",
        "@media(max-width:667px)": {
          maxWidth: "130px",
        },
      },
    },
    "& .subAdminBox": {
      backgroundColor: "rgba(252, 252, 252, 1)",
      padding: "4px",
      borderRadius: "5px",
      marginLeft: "8px",
      "@media(max-width:667px)": {
        display: "none",
      },
    },
    "& .MuiInputBase-root":{
      height:"41px",
    }
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();

  const location = useLocation();

  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const [unReadNotification, setUnReadNotification] = useState(0);

  const [open, setOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  const readNotificationList = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.readNotification,
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });

      if (res.data.responseCode === 200) {
        setUnReadNotification(res?.data?.result?.unReadCount);
        user.NotificationDataList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   readNotificationList();
  // }, []);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <Box className={classes.mainheader}>
        <Grid container alignItems="center">
          <Grid item lg={3} md={3} sm={4} xs={6}>
            <Box className="leftBox">
              <Link to="/">
                <Logo />
              </Link>
              <Box className="subAdminBox">
                <Typography variant="body2" color="primary">
                  SUPERADMIN
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={5}>
  {/* <Hidden xsDown>
    <TextField
      variant="outlined"
      placeholder="Search by User, Admin and  Company name"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Hidden> */}
</Grid>

          <Grid lg={5} md={3} sm={3} xs={6}>
            <SwipeableTemporaryDrawer />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent></DialogContent>
      </Dialog>
    </>
  );
}
