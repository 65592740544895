/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaSignOutAlt } from "react-icons/fa";

import { CgLogIn } from "react-icons/cg";
import { ImHome3 } from "react-icons/im";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ApartmentIcon from "@material-ui/icons/Apartment";
import PeopleIcon from "@material-ui/icons/People";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import TocIcon from "@material-ui/icons/Toc";
import SettingsIcon from "@material-ui/icons/Settings";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import HelpIcon from '@material-ui/icons/Help';
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";

import PersonIcon from "@material-ui/icons/Person";
const sections = [
  {
    items: [
      // {
      //   title: "Dashboard",
      //   icon: DashboardIcon,
      //   href: "/dashboard",
      // },

      // {
      //   title: "PP User Management",
      //   icon: PersonIcon,
      //   href: "/user-management",
      // },
      // {
      //   title: "Company Management",
      //   icon: ApartmentIcon,
      //   href: "/customer-management",
      // },
      // {
      //   title: "Support Management",
      //   icon: HelpIcon,
      //   href: "/support-management",
      // },
      // {
      //   title: "Sub-Super Admin Management",
      //   icon: SettingsIcon,
      //   href: "/superAdmin-management",
      // },
      // {
      //   title: "Content Management",
      //   icon: TocIcon,
      //   href: "/content-management",
      // },
      // {
      //   title: "Announcement Management",
      //   icon: AnnouncementIcon,
      //   href: "/announcement-management",
      // },

      // {
      //   title: "Admin Settings",
      //   icon: CgLogIn,
      //   href: "/referral-management",
      //   items: [
      //     {
      //       title: "Super Admins",
      //       href: "#",
      //     },
      //     {
      //       title: "Supersub Admins",
      //       href: "#",
      //     },
      //     {
      //     title: "Sub Admins",
      //       href: "#",
      //     },
      //     {
      //       title: "Admins",
      //       href: "#",
      //     }
      //   ]
      // },

      {
        title: "Account",
        icon: PersonIcon,
        href: "/Account-list",
      },
      {
        title: "User Management",
        icon: PersonIcon,
        href: "/Usermanage-list",
      },
      {
        title: "Setting",
        icon: PersonIcon,
        href: "/Setting",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      // {
      //   title: "Terms and Conditions",
      //   //icon: PieChartIcon,
      //   href: "/terms",
      // },
      // {
      //   title: "Logout",
      //   href: "/dashboard",
      //   icon: IoLogOut,
      // },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 272,
    background: "rgba(249, 249, 249, 1)",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 11%)",
  },
  desktopDrawer: {
    top: "76px",
    width: "250px",
    height: "calc(100% - 115px)",
    margin: "5px 10px 10px 15px",
    background: "rgba(249, 249, 249, 1)",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 11%)",
    borderRadius: "20px",
    marginTop: "35px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },

  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);

  const confirmationHandler = () => {
    window.localStorage.removeItem("admintoken");
    // window.localStorage.removeItem("email");
    history.push("/");
    // window.localStorage.clear();
  };
  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {sections?.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
