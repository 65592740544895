import React from "react";
import { Button, makeStyles, Typography, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 299,
    height: 215,
    // background:
    //   "linear-gradient(180deg, rgba(47, 243, 255, 7.8) 0%, rgba(2, 204, 217, 7.8) 100%)",
    // borderRadius: "24px",
    // "& .MuiDialogContent-root": {
    //   flex: "none !important",
    // },
    backgroundColor: "#181C32",
    "& .MuiDialogActions-root": {
      marginRight: "0px !important",
    },
  },
  Titlemain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23.989px",
    lineHeight: "36px",
    color: "#FFF",
    marginTop: "16px",
  },
  subMain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFF",
  },
  yesNoButton: {
    width: "82px",
    height: "37px",
    // background: "linear-gradient(180.99deg, #fff -25%, #c6f4f7 141.48%)",
    borderRadius: "10px",
    backgroundColor: "white",
    color: "#181C32",
  },
}));
export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h1" color="primary">
            {" "}
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box align="center">
            <Typography variant="h3">{desc}</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              autoFocus
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{marginLeft:"5px"}}
              onClick={() => {
                handleClose();
                confirmationHandler();
              }}
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
