import React from "react";
import Logo from "src/component/Logo";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  leftBox: {
    width: "100%",
    maxWidth: "219px",
    backgroundColor: "#0F0037",
    padding: "18px 10px",
    margin:'10px',
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gap: "20px",

    [theme.breakpoints.down("md")]: {
      maxWidth: "200px",
    },
    "@media(max-width:1279px)": {
      marginTop: "0px",
      padding: "10px",
    },
    "@media(max-width:667px)": {
      maxWidth: "140px",
    },
    "& img": {
      width: "100%",
      maxWidth: "130px",
      "@media(max-width:667px)": {
        maxWidth: "130px",
      },
    },
  },

  superText: {
    padding: "4px",
    marginLeft: "8px",
    borderRadius: "5px",
    backgroundColor: "rgba(252, 252, 252, 1)",

    "& p":{
        color: "#000",
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.leftBox}>
      <Link to="/">
        <Logo />
      </Link>
      <Box className={classes.superText}>
        <Typography>SUPERADMIN</Typography>
      </Box>
    </Box>
  );
};

export default Header;
