import React from "react";

import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerDiv: {
    padding: "5px 0",
    textAlign: "center",
    background: "#181C32",
    position: "fixed",
    bottom: "0",
    zIndex: 9999,
    width: "100%",

    "& p": {
      fontSize: "20px",
      color: "#FFF",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footerDiv}>
      <Typography className={classes.footerText} variant="h6">
        Admin panel Developed By Persona Pro
      </Typography>
    </Box>
  );
};

export default Footer;
