import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },

  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },

  // {
  //   exact: true,
  //   path: "/announcement-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/AnouncementManagement/Anouncement")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/support-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/SupportManagement/index.js")),
  // },
  // {
  //   exact: true,
  //   path: "/view-support-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/SupportManagement/ViewTicketDetails.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/new-announcement",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/AnouncementManagement/NewAnouncement")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/changePassword",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Change Password/changePassword.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/my-profile",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Profile/index.js")),
  // },
  // {
  //   exact: true,
  //   path: "/edit-profile",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Profile/editprofile.js")),
  // },

  // {
  //   exact: true,
  //   path: "/user-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/userManagement")),
  // },
  // {
  //   exact: true,
  //   path: "/add-user",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/userManagement/addUser.js")),
  // },
  // {
  //   exact: true,
  //   path: "/add-superAdmin",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/superAdminManagement/addAdmin.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/add-customer",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/customerManagement/addCustomer.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/customer-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/customerManagement")),
  // },
  // {
  //   exact: true,
  //   path: "/superAdmin-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/superAdminManagement")),
  // },
  // {
  //   exact: true,
  //   path: "/view-user-details",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/userManagement/userDetails")),
  // },

  // {
  //   exact: true,
  //   path: "/view-customer-details",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/customerManagement/customerDetails.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/view-announcement-details",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/AnouncementManagement/ViewAnnouncement.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/view-superAdmin-details",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/superAdminManagement/superAdminDetails.js")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/view-superAdmin-details",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/superAdminManagement/superAdminDetails.js")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/content-management",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/StaticContent/contentManagement")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/view-content",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/StaticContent/viewContent")),
  // },
  // {
  //   exact: true,
  //   path: "/edit-content",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/StaticContent/editContent")),
  // },


  {
    exact: true,
    path: "/Usermanage-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManage/index")),
  },
  {
    exact: true,
    path: "/Account-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Account/index")),
  },
  {
    exact: true,
    path: "/Create-Account",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Account/CreateNewAccount")),
  },
  {
    exact: true,
    path: "/Setting",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Setting/index")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
